<template>
    <div class='abilityDetails'>
        <div v-show="readyGo">加载中......</div>
        <div class="content">
            <!-- 时间 -->
            <div class="time top">
                <svg-icon icon-class="back" class="arr arr-left" @click="getDate(false)" />
                <div class="current">{{ current }}</div>
                <svg-icon v-show="current != today" icon-class="back" class="arr arr-right" @click="getDate(true)" />
            </div>
            <!-- 时间 -->
            <div class="time">
                <div class="current">{{ currentTime }}</div>
            </div>
            <div class="percentage">
                <div class="num">{{ currentHeartRate }}</div>
                <div>次/分钟</div>
            </div>
            <!-- 折线图 -->
            <div class="echart">
                <div id="BrokenLine" class="histogram"></div>
            </div>
            <!-- 提示 -->
            <div class="tipsNum ">
                <div class="mini">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最低值</div>
                    </div>
                    <div class="text">
                        <div class="num">{{ valuemin }}</div>
                        <div>次/分钟</div>
                    </div>
                </div>
                <div class="average">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>平均值</div>
                    </div>
                    <div class="text">
                        <div class="num">{{ valueavg }}</div>
                        <div>次/分钟</div>
                    </div>
                </div>
                <div class="max">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最大值</div>
                    </div>
                    <div class="text">
                        <div class="num">{{ valuemax }}</div>
                        <div>次/分钟</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content history-box">
            <!-- 标题 -->
            <div class="time">
                <div class="current">心率走势</div>
            </div>
            <!-- 折线图 -->
            <div class="echart">
                <div id="HistoryBrokenLine" class="histogram"></div>
            </div>
            <!-- 历史时间 -->
            <HistoryBar @onIndexCahnge="onIndexCahnge"></HistoryBar>
            <div class="tipsNum">
                <div class="mini">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最低值</div>
                    </div>
                    <div class="text">
                        <div class="num">{{ historyRateMin }}</div>
                        <div>次/分钟</div>
                    </div>
                </div>
                <div class="max">
                    <div class="title">
                        <div class="tipicon"></div>
                        <div>最大值</div>
                    </div>
                    <div class="text">
                        <div class="num">{{ historyRateMax }}</div>
                        <div>次/分钟</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>

import * as echarts from 'echarts'
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent
} from 'echarts/components'
import { mapGetters } from 'vuex'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import { GetWatchTypeByDate, GetWatchTypeByHistoryDate } from '@/api/index'
import { current, formatDay } from '@/util/validate'
import HistoryBar from '../../components/tabbar/HistoryBar.vue'

echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
])

export default {
    components: {
        HistoryBar
    },
    data() {
        return {
            current: current(),
            today: current(),
            yData: [],
            xData: [],
            currentHeartRate: null,
            currentTime: null,
            valueavg: null, // 平均值
            valuemax: null, // 最大值
            valuemin: null,// 最小值
            currentActiveIndex: 0,//当前tab index
            historyRateMax: 0,//历史心率最大值
            historyRateMin: 0,//历史心率最小值
            historyXData: [],
            historyYData: [],
            readyGo: false

        }
    },
    props: {

    },
    created() {

    },
    mounted() {
        this.getHeartRateData()
        this.getHistoryHeartRateData()
    },
    beforeUpdate() {
        this.readyGo = true
    },
    methods: {
        //获取当前日期的数据
        getHeartRateData() {
            GetWatchTypeByDate({
                userId: 'Mini_df80d757-6677-4cfe-8df7-e458e7684204',
                time: this.current,
                type: 1// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
            }).then(res => {
                const _data = res.data
                this.yData = _data.timelist[0].value
                this.xData = _data.timelist[0].time
                this.valueavg = _data.valueavg
                this.valuemax = _data.valuemax
                this.valuemin = _data.valuemin
                this.yData.forEach((v, i) => {
                    if (v != 0) {
                        this.currentHeartRate = v,
                            this.currentTime = this.xData[i]
                    }
                })
                this.$nextTick(() => {
                    this.initializationBrokenLine()
                })
            }).catch(err => {
                this.yData = []
                this.xData = []
                this.$nextTick(() => {
                    this.initializationBrokenLine()
                })
            })
        },

        // 初始化折线图
        initializationBrokenLine() {
            let _this = this
            if (!document.getElementById('BrokenLine')) {
                return
            }
            let chartDom = document.getElementById('BrokenLine')
            let myChart = echarts.init(chartDom)
            let option
            option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: (value) => {
                        let xIndex = value[0].dataIndex
                        this.currentHeartRate = this.yData[xIndex]
                        this.currentTime = this.xData[xIndex]
                    }
                },
                xAxis: {
                    data: this.xData,
                    nameLocation: "end",
                    boundaryGap: false,
                    axisLine: {
                        show: true // 是否显示坐标轴
                    },
                    axisTick: {
                        show: true // 是否显示坐标轴刻度
                    },
                    splitLine: { show: true },// 去除网格线
                    axisLabel: {
                        showMinLabel: true,
                        showMaxLabel: true,
                        interval: Math.ceil(this.xData.length / 4),
                        margin: 20,
                        max: 500,
                        min: 0,
                        formatter: (v, i) => {
                            if (v == '00:00' || v == '06:00' || v == '12:00' || v == '18:00' || v == '24:00') {
                                return v
                            }
                            return ''
                        },
                    },
                    max: 200,
                    min: 0,
                    splitNumber: 5,
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    max: 200,
                    min: 0,
                    splitNumber: 5,
                },
                grid: {
                    left: "10%",
                    right: "7%",
                    bottom: "20%"
                },
                series: [
                    {
                        type: 'line',
                        symbol: 'none',
                        sampling: 'lttb',
                        itemStyle: {
                            color: '#FF9281'
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#FF9180'
                                },
                                {
                                    offset: 1,
                                    color: '#FFC9C0'
                                }
                            ])
                        },
                        data: this.yData
                    }
                ]
            }
            option && myChart.setOption(option)
        },

        //获取历史心率数据
        getHistoryHeartRateData() {
            GetWatchTypeByHistoryDate({
                userId: 'Mini_df80d757-6677-4cfe-8df7-e458e7684204',
                timeType: this.currentActiveIndex + 1,//1近一周 2近一个月 3近三个月 4近半年 5近一年
                dateTtpe: 1// 查询类型 1心率 2血压 3血氧 4睡眠 5压力 6步数
            }).then(res => {
                const _data = res.data
                // console.log('------',_data)
                let _list = _data.daysList.reverse()
                this.historyXData = _list.map(item => {
                    return item.time;
                })
                this.historyYData = _list.map(item => {
                    return item.value;
                })
                this.historyRateMax = _data.heartRateMax
                this.historyRateMin = _data.heartRateMin
                this.$nextTick(() => {
                    this.initHistoryBrokenLine()
                })
            })
        },

        //初始化历史折线图
        initHistoryBrokenLine() {
            if (!document.getElementById('HistoryBrokenLine')) {
                return
            }
            let chartDom = document.getElementById('HistoryBrokenLine')
            let myChart = echarts.init(chartDom)
            let option
            option = {
                tooltip: {
                    trigger: 'axis',
                },
                xAxis: {
                    type: 'category',
                    data: this.historyXData,
                    //   nameLocation: "end",
                    boundaryGap: false,
                    axisLine: {
                        show: false // 是否显示坐标轴
                    },
                    axisTick: {
                        show: false // 是否显示坐标轴刻度
                    },
                    splitLine: { show: false },// 去除网格线
                    axisLabel: {
                        showMinLabel: true,
                        showMaxLabel: true,
                        margin: '20',
                        formatter: (v, i) => {
                            return this.currentActiveIndex == 0 || this.currentActiveIndex == 1 ? v.substring(5) : v
                        },
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    // max : 100,
                    min: 0,
                    splitNumber: 3,
                },
                grid: {
                    left: "10%",
                    right: "7%",
                    bottom: "20%"
                },
                series: [
                    {
                        type: 'line',
                        symbol: 'none',
                        sampling: 'lttb',
                        itemStyle: {
                            color: '#FF9281'
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#FF9180'
                                },
                                {
                                    offset: 1,
                                    color: '#FFC9C0'
                                }
                            ])
                        },
                        data: this.historyYData
                    }
                ]
            }
            option && myChart.setOption(option)
        },
        onIndexCahnge(index) {
            this.currentActiveIndex = index
            this.getHistoryHeartRateData()
        },
        getDate(flag) {
            let t = flag ? new Date(this.current).getTime() + 86400000 : new Date(this.current).getTime() - 86400000
            this.current = current(new Date(t))
            this.getHeartRateData()
        },

    },
    watch: {

    },
    computed: {
        ...mapGetters([
            'userid'
        ])
    }
}
</script>
<style lang='scss' scoped>
.abilityDetails {
    height: 100%;
    padding: 0 20px;
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/modularBag3.png') no-repeat;
    background-size: 100%;
    background-color: #f7f7f7;

    .content {
        width: 100%;
        border-radius: 15px;
        background: #fff;
        // height: 80%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .time {
            color: #868686;
            display: flex;
            align-items: center;
            font-size: 16px;
            position: relative;

            .arr-left {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: -10px;
            }

            .arr-right {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%) rotate(180deg);
                right: -10px;
            }

            &.top {
                margin-bottom: 20px;
                display: flex;
                align-content: center;
            }

            .current {
                font-size: 20px;
                font-weight: 700;
                margin: 0 10px;
            }
        }

        .echart {
            width: 100%;
            position: relative;

            .histogram {
                width: 100%;
                height: 240px;
                box-sizing: border-box;

                &.p-20 {
                    padding: 0 20px;
                }

                // /deep/ canvas {
                //     width: 110% !important;
                //     height: 120% !important;
                //     left: -2% !important;
                // }
            }

            .timenode {
                width: 90%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 10px;
                right: 0;
                color: #808080;
            }
        }

        .percentage {
            display: flex;
            align-items: center;
            color: #858585;
            font-size: 20px;
            margin-top: 20px;

            .num {
                color: #4F4F4F;
                font-size: 34px;
            }
        }

        .tipsNum {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .mini,
            .average,
            .max {
                width: 32%;
                padding: 5px 10px;
                border-radius: 5px;

                .title {
                    display: flex;
                    align-items: center;
                    color: #808080;

                    .tipicon {
                        width: 5px;
                        height: 10px;
                        border-radius: 5px;
                        margin-right: 5px;
                    }
                }

                .text {
                    color: #808080;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    font-size: 10px;

                    .num {
                        color: #282828;
                        // font-size: 24px;
                        font-size: 16px;
                    }
                }

            }

            .mini {
                border: 1px solid #D330FF;
                background: #FEF8FE;

                .tipicon {
                    background: #D330FF;
                }
            }

            .average {
                border: 1px solid #FCB334;
                background: #FFFBF5;

                .tipicon {
                    background: #FCB334;
                }
            }

            .max {
                border: 1px solid #2FBE95;
                background: #F4FBF9;

                .tipicon {
                    background: #2FBE95;
                }
            }
        }

        &.history-box {

            // padding: 20px 0;
            .tipsNum {
                // padding: 0 20px;
                box-sizing: border-box;
            }

            .mini,
            .max {
                width: 45%;
            }
        }

        // .history-tabbar {
        //     width:100%;
        //     display: flex;
        //     justify-content: space-evenly;
        //     font-size: 15px;
        //     margin-bottom: 20px;

        //     .tab {
        //         // width: 55px;
        //         // padding: 8px;
        //         color: #868686;
        //         padding:5px 10px;
        //         &.active{
        //             border-radius: 20px;
        //             text-align: center;
        //             background: #ffe6e2;
        //             color: #ff9180;
        //             // padding: 10px 15px;
        //         }
        //     }
        // }
    }
}
</style>
  